<template>
  <article class="preview">
    <div class="preview__thumbnail"></div>
    <div class="preview__text">
      <h2 class="preview__text__header" style="font-family: 'KBbubblegum', sans-serif !important;">Vin d'honneur & mariage</h2>
      <p class="preview__text__description">
        Ajoutez une touche sucrée et ludique à votre vin d'honneur avec notre formule originale, qui ravira petits et grands. Plutôt qu’un simple apéritif, nous proposons un véritable voyage dans le monde des douceurs et des gourmandises.
      </p>
      <router-link to="/product/vin-dhonneur" class="preview__text__link"
        ><button class="preview__text__link__btn default-btn" style="border-radius: 15px; color: white; background-color: #3c047c">
          Voir le produit
        </button></router-link
      >
    </div>
  </article>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ZX9Preview",
};
</script>

<style lang="scss" scoped>
.preview {
  margin: 4rem auto 0 auto;
  width: 35.7rem;
  background: #ff7db9;
  background-image: url("../../assets/home/desktop/pattern-circles.svg");
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 5.5rem 2.4rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media (min-width: 768px) {
    margin-top: 9.6rem;
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    margin-top: 16.8rem;
    background-size: cover;
    background-position-x: -13rem;
    flex-direction: row;
    justify-content: center;
    width: 111rem;
  }

  * {
    text-align: center;
  }

  &__thumbnail {
    width: 19.225rem;
    height: 20.7rem;
    background: url("../../assets/home/mobile/mariage.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: 768px) {
      background: url("../../assets/home/tablet/mariage.gif");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      width: 19.7rem;
      height: 23.7rem;
    }
    @media (min-width: 1205px) {
      background: url("../../assets/home/desktop/mariage.gif");
      background-repeat: no-repeat;
      background-position: center;
      width: 41.023rem;
      height: 49.3rem;
      align-self: flex-end;
      top: 6.7rem;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      width: 34.9rem;
    }

    @media (min-width: 1205px) {
      align-items: flex-start;
    }

    &__header {
      margin-top: 3.2rem;
      margin-bottom: 2.4rem;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 4rem;
      letter-spacing: 0.129rem;
      color: white;

      @media (min-width: 768px) {
        font-size: 5.6rem;
        line-height: 5.8rem;
        letter-spacing: 0.2rem;
        width: 26rem;
      }

      @media (min-width: 1205px) {
        text-align: left;
      }
    }

    &__description {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: white;
      margin-bottom: 2.4rem;

      @media (min-width: 1205px) {
        text-align: left;
      }
    }

    &__link {
      &__btn {
        background: black;
        transition: all 0.3s ease;

        &:hover {
          background: #4c4c4c;
        }
      }
    }
  }
}
</style>
