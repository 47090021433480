<template>
  <nav class="navbar">
    <router-link
      @click="$emit('toggle-menu-show', 'logo')"
      to="/"
      class="navbar__homeLink"
    >
      <img
        src="../assets/shared/desktop/logo-white.png"
        alt="Logo au coeur de la malice"
        class="navbar__homeLink__logo"
        style="max-width: 150px !important;"
      /></router-link>
    <ul class="navbar__desktop-menu">
      <li class="navbar__desktop-menu__link">
        <router-link to="/">Accueil</router-link>
        <router-link to="/category/menus">Nos menus</router-link>
      </li>
    </ul>
    <button
      class="navbar__cart-btn"
      @click="$emit('toggle-menu-show', 'cart')"
      style="display: none"
    >
      <span v-if="cart_lenght() > 0" class="badge">{{ cart_lenght() }}</span>
    </button>
    <h1 style="color: white">07 68 33 99 93</h1>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Navbar",
  data() {
    return { myVar: "hello" };
  },
  methods: {
    cart_lenght(){
      return JSON.parse(localStorage.getItem("cart")).length;
    }
  },
  emits: ["toggle-menu-show"],
};
</script>

<style lang="scss" scoped>
.navbar {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.2rem 2.4rem;
  position: relative;
  border-bottom: 0.1rem solid #353535;
  z-index: 3;

  @media (min-width: 768px) {
    justify-content: flex-start;
    padding: 3.2rem 0;
    margin: 0 auto;
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    width: 111rem;
  }

  button {
    border: none;
  }

  &__mobile-menu-btn {
    background: url("../assets/shared/tablet/icon-hamburger.svg");
    width: 1.6rem;
    height: 1.5rem;

    @media (min-width: 1205px) {
      display: none;
    }
  }

  &__homeLink {
    display: flex;

    @media (min-width: 768px) {
      margin-left: 4.2rem;
    }

    @media (min-width: 1205px) {
      margin-left: 0;
    }
  }

  &__desktop-menu {
    margin-left: auto;
    margin-right: 30%;
    display: none;

    @media (min-width: 1205px) {
      display: flex;
    }

    &__link {
      a {
        color: white;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 2.5rem;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        margin-left: 3.4rem;
        transition: all 0.3s ease;

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          color: #e91e63;
        }
      }
    }
  }

  &__cart-btn {
    background: url("../assets/shared/desktop/icon-cart.svg");
    width: 2.3rem;
    height: 2rem;

    @media (min-width: 768px) {
      position: absolute;
      right: 4rem;
    }
  }

  .badge {
    background-color: #e91e63; /* Couleur de fond du badge */
    color: white; /* Couleur du texte du badge */
    border-radius: 50%; /* Pour un badge circulaire */
    padding: 5px 10px; /* Ajustez la taille du badge en fonction de vos besoins */
    position: absolute; /* Pour positionner le badge par rapport au bouton */
    top: -20px; /* Ajustez la position verticale du badge en fonction de vos besoins */
    right: -20px; /* Ajustez la position horizontale du badge en fonction de vos besoins */
  }
}
</style>
