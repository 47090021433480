<template>
  <section class="about">

    <div class="carousel">
      <div class="carousel__inner" :style="{ transform: `translateX(-${currentIndex * 33.330}%)` }">
        <div
            class="carousel__item"
            v-for="(image, index) in images"
            :key="index"
            :style="{ backgroundImage: `url(${image})` }"
            @click="slideshow_index = currentIndex"
        ></div>
      </div>


      <div class="carousel__indicators">
      <span
          class="carousel__indicator"
          v-for="(image, index) in images"
          :key="index"
          :class="{ active: currentIndex === index }"
          @click="goToSlide(index)"
      ></span>
      </div>

    </div>

    <vue-gallery-slideshow :images="images" :index="slideshow_index" @close="slideshow_index = 0"></vue-gallery-slideshow>


    <div class="about__text">
      <h2 class="about__text__heading">
        Remi VANDENABEELE <span style="font-size: 3.5rem">VOTRE</span> traiteur !
      </h2>
      <p class="about__text__description">
        A l’age de 12 ans, je suis tombé dans l’amour de la restauration grâce à mon oncle Robert. J’ai intégré à 14 ans l’école hôtelière de Bailleul. Ayant exercé auprès de grands chefs talentueux, j’ai pu acquérir de bonnes bases ! De la cuisine en collectivité au restaurant traditionnel, où j’occupais un poste de chef, j’ai trouvé ma voie en me lançant en tant que Traiteur à domicile. Je serais ravi de vous réaliser une prestation de qualité, élaborée avec des produits frais et locaux.
      </p>
      <router-link
          class="hero__product-link"
          to="/contact"
      >
        <button class="default-btn" style="border-radius: 20px; margin-top: 2rem;">Contactez-moi</button>
      </router-link>
    </div>

  </section>

</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AboutSection",
  components: {
    VueGallerySlideshow
  },
  data() {
    return {
      currentIndex: 0,
      slideshow_index: null,
      images: [
        "https://lvdneng.rosselcdn.net/sites/default/files/dpistyles_v2/ena_16_9_in_line/2023/05/23/node_1331051/56230895/public/2023/05/23/B9734337623Z.1_20230523104732_000%2BGT0MQO6CL.1-0.jpg?itok=K6Y6tgCy1684834413",
        "https://lvdneng.rosselcdn.net/sites/default/files/dpistyles_v2/ena_16_9_in_line/2021/07/18/node_1046440/52712260/public/2021/07/18/B9727718942Z.1_20210718162447_000%2BGF7IIDN6R.2-0.jpg?itok=UY5BWlv21626729011",
        "https://lvdneng.rosselcdn.net/sites/default/files/dpistyles_v2/ena_16_9_extra_big/2021/07/18/node_1046440/52712259/public/2021/07/18/B9727718942Z.1_20210718162447_000%2BGNOIIDN1R.2-0.jpg?itok=OVmoe_6s1626618295",
      ],
      autoSlideInterval: null,
    };
  },

  mounted() {
    this.startAutoSlide();
  },

  beforeDestroy() {
    clearInterval(this.autoSlideInterval);
  },

  methods: {
    goToSlide(index) {
      this.currentIndex = index;
    },

    startAutoSlide() {
      this.autoSlideInterval = setInterval(() => {
        console.log("this.images.length", (this.images.length - 1));
        if(this.currentIndex >= (this.images.length -1)){
          this.currentIndex = 0;
        }else {
          this.currentIndex++;
        }
        this.goToSlide(this.currentIndex);
      }, 3000); // Change d'image toutes les 3 secondes
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  margin: 12rem auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35.7rem;

  @media (min-width: 768px) {
    margin: 9.6rem auto;
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    width: 111rem;
    margin: 20rem auto;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  &__imageXX {
    width: 100%;
    border-radius: 0.8rem;
    background: url("../../assets/shared/mobile/image-best-gear.jpg");
    background-size: cover;
    background-position: top center;
    height: 30rem;

    @media (min-width: 768px) {
      background: url("../../assets/shared/tablet/image-best-gear.jpg");
      background-size: cover;
      background-position: center;
    }

    @media (min-width: 1205px) {
      width: 54rem;
      height: 58.8rem;
      background: url("../../assets/shared/desktop/image-best-gear.jpg");
      background-size: cover;
      background-position: center;
    }
  }

  &__text {
    @media (min-width: 1205px) {
      & * {
        text-align: left;
      }
    }

    &__heading {
      margin-top: 4rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.825rem;
      letter-spacing: 0.1rem;

      @media (min-width: 768px) {
        font-size: 4rem;
        line-height: 4.4rem;
        letter-spacing: 0.143rem;
        width: 57.3rem;
        margin-top: 6.3rem;
      }

      @media (min-width: 1205px) {
        width: 44.5rem;
        margin-top: 0;
      }

      & span {
        color: #e91e63;
      }
    }

    &__description {
      margin-top: 3.2rem;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: #7d7d7d;

      @media (min-width: 768px) {
        width: 57.3rem;
      }

      @media (min-width: 1205px) {
        width: 44.5rem;
      }
    }
  }
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 54rem;
  overflow: hidden;
  border-radius: 0.8rem;
}

.carousel__inner {
  display: flex;
  width: 300%; /* Ajuste en fonction du nombre d'images */
  transition: transform 0.5s ease-in-out;
}

.carousel__item {
  flex: 1;
  background-size: cover;
  background-position: center;
  max-width: 100%;
  border-bottom-left-radius: 100px !important;
  border-top-right-radius: 100px !important;
  height: 30rem; /* Ajuste en fonction de la taille d'image */
}

@media (min-width: 768px) {
  .carousel__item {
    height: 40rem;
  }
}

@media (min-width: 1205px) {
  .carousel__item {
    height: 58.8rem;
  }
}

.carousel__indicators {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 20px;
}

.carousel__indicator {
  width: 15px;
  height: 15px;
  background-color: rgba(84, 64, 64, 0.5);
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.carousel__indicator.active {
  background-color: #e91e63
}

</style>
