<template>
  <div>
    <Header
        category="CGU et mentions légales"
        @toggle-menu-show="$emit('toggle-menu-show', $event)"
    />
    <main>
      <p class="back-link" @click="$router.back()">Retour en arrière</p>

      <div class="container">
        <h1>Mentions Légales</h1>

        <h2>1. Présentation du site</h2>
        <p>En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site <u><strong>aucoeurdelamalice</strong></u> l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
        <ul>
          <li><strong>Propriétaire :</strong> AuCoeurDeLaMalice – 8 rue Victor Hugo, Loos</li>
          <li><strong>Créateur :</strong> JC2MSC</li>
          <li><strong>Responsable publication :</strong> Vandenabeele Rémi – aucoeurdelamalice@gmail.com</li>
          <li><strong>Webmaster :</strong> Jérémy Meurin – jeremy.meurin@orange.fr</li>
          <li><strong>Hébergeur :</strong> Private.Heberg – privateheberg.net</li>
        </ul>

        <h2>2. Conditions générales d’utilisation du site et des services proposés</h2>
        <p>L’utilisation du site <u><strong>aucoeurdelamalice</strong></u> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site <u><strong>aucoeurdelamalice</strong></u> sont donc invités à les consulter de manière régulière.</p>

        <h2>3. Description des services fournis</h2>
        <p>Le site <u><strong>aucoeurdelamalice</strong></u> a pour objet de fournir une information concernant l’ensemble des activités de la société. <u><strong>aucoeurdelamalice</strong></u> s’efforce de fournir sur le site des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des oublis, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>

        <h2>4. Propriété intellectuelle et contrefaçons</h2>
        <p><u><strong>aucoeurdelamalice</strong></u> est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : <u><strong>aucoeurdelamalice</strong></u>.</p>

        <h2>5. Limitations de responsabilité</h2>
        <p><u><strong>aucoeurdelamalice</strong></u> ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site <u><strong>aucoeurdelamalice</strong></u>, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.</p>

        <h2>6. Gestion des données personnelles</h2>
        <p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
        <p>À l’occasion de l’utilisation du site <u><strong>aucoeurdelamalice</strong></u>, peuvent êtres recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé au site <u><strong>aucoeurdelamalice</strong></u>, le fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur.</p>
        <p>En tout état de cause <u><strong>aucoeurdelamalice</strong></u> ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site <u><strong>aucoeurdelamalice</strong></u>. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur du site <u><strong>aucoeurdelamalice</strong></u> l’obligation ou non de fournir ces informations.</p>
        <p>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.</p>
        <p>Aucune information personnelle de l’utilisateur du site <u><strong>aucoeurdelamalice</strong></u> n’est publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l’hypothèse du rachat de <u><strong>aucoeurdelamalice</strong></u> et de ses droits permettrait la transmission des dites informations à l’éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis-à-vis de l’utilisateur du site <u><strong>aucoeurdelamalice</strong></u>.</p>
        <p>Le site n’est pas déclaré à la CNIL car il ne recueille pas d’informations personnelles. .</p>
        <p>Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>

        <h2>7. Droit applicable et attribution de juridiction</h2>
        <p>Tout litige en relation avec l’utilisation du site <u><strong>aucoeurdelamalice</strong></u> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.</p>

        <h2>8. Les principales lois concernées</h2>
        <ul>
          <li>Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.</li>
          <li> Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.</li>
        </ul>

        <h2>9. Lexique</h2>
        <ul>
          <li>Utilisateur : Internaute se connectant, utilisant le site susnommé.</li>
          <li>Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</li>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>

import Header from "@/components/CategoryPage/Header";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Header
  },
  emits: ["toggle-menu-show"],
};
</script>

<style lang="scss" scoped>

main {
  width: 35.7rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    width: 111rem;
  }
}

.back-link {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: #7d7d7d;
  margin: 1.6rem 0 2.4rem 0;
  display: block;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #e91e63;
  }

  @media (min-width: 768px) {
    margin-top: 3.3rem;
  }

  @media (min-width: 1205px) {
    margin-top: 7.9rem;
  }
}

h1, h2, h3 {
  color: hotpink;
}
p, ul, li{
  margin: 10px 0;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: #7d7d7d;
}
.container {
  margin: auto;
  padding-bottom: 6rem;
}
</style>

