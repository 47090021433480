<template>
  <div class="category">
    <div class="category__image-container">
      <img
          v-if="product === 'Méchouis'"
          src="../../assets/shared/desktop/jambon_to.png"
          alt="headphones"
          class="category__thumbnail"
      />
      <img
          v-if="product === 'Poêlés'"
          src="../../assets/shared/desktop/poele_to.png"
          alt="speakers"
          class="category__thumbnail"
      />
      <img
          v-if="product === 'Apéros'"
          src="../../assets/shared/desktop/verrine.png"
          alt="earphones"
          class="category__thumbnail"
      />
      <img
          v-if="product === 'Menus'"
          src="../../assets/shared/desktop/menu_to.png"
          alt="earphones"
          class="category__thumbnail"
      />
      <img
          v-if="product === 'Friteries'"
          src="../../assets/shared/desktop/friterie.png"
          alt="earphones"
          class="category__thumbnail"
      />
      <img
          v-if="product === 'Desserts'"
          src="../../assets/shared/desktop/dessert.png"
          alt="earphones"
          class="category__thumbnail"
      />
    </div>
    <h2 class="category__name" style="font-family: 'KBbubblegum', sans-serif !important;">{{ product }}</h2>
    <router-link
        class="category__link"
        :to="{ name: 'CategoryPage', params: { category: `${product}` } }"
        @click="scrollToTop"
    >

      <div>
        <span style="margin: 10px; margin-right: 0px !important; letter-spacing: normal">Voir la catégorie</span>
        <img
            style="margin-top:0px !important;"
            src="../../assets/shared/desktop/icon-arrow-right.svg"
            class="category__link__icon"
        />
      </div>
    </router-link>
  </div>
</template>


<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Category",
  props: {
    product: String,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f1f1f1;
  margin: 0 auto 6.8rem auto;
  border-radius: 3rem;
  max-width: 35.7rem;

  @media (min-width: 768px) {
    margin: 0;
    margin-right: 1rem;
    max-width: auto;
    min-width: 22.3rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: 1205px) {
    margin-right: 3rem;
    min-width: 35rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__image-container {
    height: 100px; // Fixe la hauteur du conteneur
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -2rem; // Déplace les images vers le haut si nécessaire
  }

  &__thumbnail {
    max-height: 100%; // Assure que l'image ne dépasse pas la hauteur du conteneur
    max-width: 100%;
  }

  &__name {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.049rem;
    letter-spacing: 0.107rem;

    @media (min-width: 1205px) {
      font-size: 1.8rem;
      line-height: 2.459rem;
      letter-spacing: 0.129rem;
    }
  }

  &__link {
    margin-top: 1.7rem;
    margin-bottom: 2.2rem;
    color: #797979;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.776rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
      color: #d87d4a;
    }

    &__icon {
      margin-right: 0.6rem;
      margin-left: 0.4rem;
    }
  }
}
</style>

