import Vue from 'vue'
import App from "./App.vue";
import router from "./router";
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta'

//createApp(App).use(router).mount("#app");
Vue.use(VueRouter);
Vue.use(VueMeta, {
  // this is optional and disables the `metaInfo` attribute when printing to HTML
  keyName: 'metaInfo',
});

new Vue({
  router,
  render: h => h(App)
  }).$mount('#app')