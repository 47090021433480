<template>
  <div>
    <Header
        category="Contact"
        @toggle-menu-show="$emit('toggle-menu-show', $event)"
    />
    <main>
      <p class="back-link" @click="$router.back()">Retour en arrière</p>

      <div class="container" style="margin-bottom: 2rem !important;">
        <form
            v-if="!formSubmitted && !isError"
            class="contact-form"
            id="contact-form"
            :class="{ 'form-submitted': formSubmitted }"
            @submit.prevent="submit"
        >
          <div class="alert alert-danger" v-if="formInvalid">
            Veuillez corriger les erreurs dans le formulaire.
          </div>

          <input type="hidden" v-model="contact_number" v-if="!formSubmitted">

          <div class="form-group">
            <label for="message"><h4>Message</h4></label>
            <textarea
                id="message"
                v-model="message"
                @input="validateForm"
                placeholder="Parlez-moi du problème que vous souhaitez résoudre."
                class="form-control"
                :class="{'is-valid': validMessage, 'is-invalid': message && !validMessage}"
                required
            ></textarea>
            <small v-if="message && !validMessage" class="text-danger">
              Le message doit contenir au moins 10 caractères.
            </small>
          </div>

          <div class="form-group">
            <label for="contact-method"><h4>Comment puis-je vous contacter ?*</h4></label>
            <div style="margin-top: 5px">
              <input
                  id="name"
                  v-model="name"
                  @input="validateForm"
                  placeholder="Nom & Prénom"
                  type="text"
                  class="form-control"
                  :class="{'is-valid': validName, 'is-invalid': name && !validName}"
                  required
                  aria-invalid="true"
              >
              <small v-if="name && !validName" class="text-danger">
                Le nom doit contenir au moins 2 caractères.
              </small>
            </div>
            <div style="margin-top: 5px">
              <input
                  id="email"
                  v-model="email"
                  @input="validateForm"
                  placeholder="Adresse e-mail"
                  type="email"
                  class="form-control"
                  :class="{'is-valid': validEmail, 'is-invalid': email && !validEmail}"
                  required
              >
              <small v-if="email && !validEmail" class="text-danger">
                Veuillez entrer une adresse e-mail valide.
              </small>
            </div>
            <div style="margin-top: 5px">
              <input
                  id="phone"
                  v-model="phone"
                  @input="validateForm"
                  @keypress="isNumberKey"
                  placeholder="06 12 34 56 78"
                  type="tel"
                  class="form-control"
                  :class="{'is-valid': validPhone, 'is-invalid': phone && !validPhone}"
                  pattern="^0[1-9]([ .-]?[0-9]{2}){4}$"
                  required
              >
              <small v-if="phone && !validPhone" class="text-danger">
                Le numéro de téléphone doit être au format français (06 12 34 56 78).
              </small>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-dark btn-lg" :disabled="!formValid">
              Envoyer
            </button>
          </div>
        </form>

        <div v-if="formSubmitted && !isError" class="success-message">
          <i class="fas fa-check-circle" style="color: #28a745; font-size: 2rem;"></i>
          <h4 style="margin-bottom: 20px">Message bien envoyé !</h4>
          <p>Votre message va être traité dès que possible.</p>
        </div>

        <div v-if="isError" class="error-message">
          <h4 style="color: red; margin-bottom: 20px">Erreur lors de l'envoi</h4>
          <p style="margin-bottom: 20px">Votre message n'a pas pu être envoyé à cause d'une erreur. Veuillez réessayer.</p>
          <button @click="retry" class="btn btn-dark btn-lg">Réessayer</button>
        </div>

        <div class="loading-container" v-if="isLoading">
          <div class="loader"></div>
          <h4 class="loading-text">ENVOI EN COURS...</h4>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "@/components/CategoryPage/Header";
import { init } from '@emailjs/browser';
import emailjs from '@emailjs/browser';

init("user_PQL8Xr3h3DaXEXNnbF5I4");

export default {
  name: `Contact`,
  data() {
    return {
      contact_number: "",
      email: "",
      name: "",
      phone: "",
      message: "",
      formSubmitted: false,
      isLoading: false,
      formInvalid: false,
      isError: false,
      validEmail: false,
      validName: false,
      validPhone: false,
      validMessage: false,
      formValid: false
    };
  },
  components: {
    Header
  },
  emits: ["toggle-menu-show"],
  methods: {
    validateForm() {
      this.validEmail = this.validateEmail(this.email);
      this.validName = this.name.length > 1;
      this.validPhone = /^0[1-9]([ .-]?[0-9]{2}){4}$/.test(this.phone);
      this.validMessage = this.message.length > 10;

      this.formValid = this.validEmail && this.validName && this.validPhone && this.validMessage;
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    isNumberKey(event) {
      const charCode = event.keyCode ? event.keyCode : event.which;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 32 && charCode !== 45) {
        event.preventDefault();
      }
    },
    submit() {
      if (this.formValid) {
        this.formInvalid = false;
        this.isLoading = true;
        this.formSubmitted = false;
        this.isError = false;
        this.contact_number = Math.random() * 100000 | 0;
        emailjs.send(
            'service_376tuwq',
            'template_ozrfjdp',
            { email: this.email, name: this.name, message: this.message, phone: this.phone }
        ).then((response) => {
          this.formSubmitted = true;
          this.isLoading = false;
          console.log("success", response);
        }, (error) => {
          this.isLoading = false;
          this.isError = true;
          console.log("error", error);
        });
      } else {
        this.formInvalid = true;
      }
    },
    retry() {
      this.isError = false;
      this.formSubmitted = false;
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  font-size: 1.8rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  margin-bottom: 10px;
  display: block;
  color: #333;
}

input, textarea {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  transition: border 0.3s;
}

.is-valid {
  border-color: #28a745;
}

.is-invalid {
  border-color: #dc3545;
}

textarea {
  resize: none;
  height: 150px;
}

small.text-danger {
  color: #dc3545;
  margin-top: 5px;
  display: block;
}

.btn {
  background-color: #e91e63;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn:hover:not([disabled]) {
  background-color: #0056b3;
}

.alert {
  color: red;
}

.loading-container {
  text-align: center;
}

.loading-text {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  .btn {
    width: 100%;
  }
}

main {
  width: 35.7rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    width: 111rem;
  }
}

.back-link {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: #7d7d7d;
  margin: 1.6rem 0 2.4rem 0;
  display: block;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #e91e63;
  }

  @media (min-width: 768px) {
    margin-top: 3.3rem;
  }

  @media (min-width: 1205px) {
    margin-top: 7.9rem;
  }
}

/* Styles précédents + ajout des styles pour les messages de succès et d'erreur */
.success-message, .error-message {
  text-align: center;
  margin-top: 20px;
}

.success-message i {
  margin-bottom: 10px;
  display: block;
}

.error-message h4, .success-message h4 {
  color: #28a745;
}

.error-message p, .success-message p {
  color: #333;
}
</style>
