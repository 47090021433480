<template>
  <section class="hero">
    <p class="hero__product-tag">Traiteur&nbsp;Loos&nbsp;-&nbsp;Lille</p>
    <h1 class="hero__product-name" style="font-family: 'KBbubblegum', sans-serif !important;">Au&nbsp;Coeur&nbsp;de&nbsp;la&nbsp;Malice</h1>
    <p class="hero__product-description">
      Pour touts vos événements aux alentours de Lille et sur Lille.
      Nos prestations et leur prix !
    </p>
    <router-link
      class="hero__product-link"
      to="/category/Méchouis"
    >
      <button class="hero__product-link__btn default-btn wa-btn-text-only" style="border-radius: 20px">Voir les prestations</button>
    </router-link>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Hero",
};
</script>

<style lang="scss" scoped>


.wa-btn-text-only{
  animation:nom_anim_bouton1 1s infinite alternate
}

@keyframes nom_anim_bouton1{
  from{
    box-shadow:0 0 5px #e91e63
  }
  to{
    box-shadow:0 0 15px hsl(0, 0%, 75%)
  }

}

.hero {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10.8rem 2.3rem 11.2rem 2.3rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 12.6rem 19.5rem 16.7rem 19.5rem;
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    padding: 12.8rem 0 15.8rem 0;
    width: 111rem;
  }

  * {
    text-align: center;
  }

  &__product-tag {
    text-transform: uppercase;
    color: #8c8c8c;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.912rem;
    letter-spacing: 0.5rem;
  }

  &__product-name {
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 4rem;
    letter-spacing: 0.129rem;
    margin: 1.6rem 0 2.4rem 0;

    @media (min-width: 768px) {
      font-size: 5.6rem;
      line-height: 5.8rem;
      letter-spacing: 0.2rem;
    }

    @media (min-width: 1205px) {
      margin: 2.4rem 0;
    }
  }

  &__product-description {
    color: #9c9c9c;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin-bottom: 2.8rem;
    width: 32.8rem;

    @media (min-width: 1205px) {
      width: 37.9rem;
      margin-bottom: 4rem;
      text-align: center;
    }
  }
}
</style>
