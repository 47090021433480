<template>
<div>
  <Header @toggle-menu-show="$emit('toggle-menu-show', $event)" />
  <main>
    <p class="back-link" @click="$router.back()">Retour en arrière</p>
    <section class="overview">
      <img :src="editSrc" :alt="currentProduct.name" class="overview__image" @click="slideshow_index = 0"/>
      <div class="overview__text">
        <p class="overview__text__tag" v-show="currentProduct.new">
          Nouveau produit
        </p>
        <h1 class="overview__text__title" style="font-family: 'KBbubblegum', sans-serif !important;">{{ currentProduct.name }}</h1>
        <p class="overview__text__description">
          {{ currentProduct.description }}
        </p>
        <p class="overview__text__price" v-if="currentProduct.price != 0">A partir de <span style="border: #e91e63 1px solid; border-radius: 10px; padding: 10px">{{ currentProduct.price }}&nbsp;€&nbsp;/personne</span></p>
        <p class="overview__text__price" v-if="currentProduct.price == 0"><span style="border: #e91e63 1px solid; border-radius: 10px; padding: 10px">Uniquement sur devis</span></p>
        <router-link
            class="hero__product-link"
            to="/contact"
            v-if="currentProduct.price == 0"
        >
          <button class="default-btn" style="border-radius: 20px; margin-bottom: 20px">Contactez-moi</button>
        </router-link>

        <div class="overview__text__btn-section" style="display: none">
          <div class="overview__text__btn-section__number">
            <button
              class="overview__text__btn-section__number__less"
              @click="decreaseTotal"
            >
              -
            </button>
            <p class="overview__text__btn-section__number__value">
              {{ total }}
            </p>
            <button
              class="overview__text__btn-section__number__more"
              @click="increaseTotal"
            >
              +
            </button>
          </div>
          <button
            :class="[
              'overview__text__btn-section__btn',
              'default-btn',
              justAdded ? 'just-added' : '',
            ]"
            @click="addToCartHandler"
          >
            {{ justAdded ? "Déjà dans le panier" : "Ajouter au panier" }}
          </button>
        </div>
      </div>
    </section>
    <section class="details">
      <div class="details__features">
        <h3 class="details__features__heading">A savoir ...</h3>
        <p
          class="details__features__text"
          v-for="paragraph in editedText"
          :key="paragraph"
        >
          {{ paragraph }}
        </p>
      </div>
      <div class="details__box">
        <h3 class="details__box__heading">Contenu</h3>
        <ul class="details__box__list">
          <li
              class="details__box__list__item"
              v-for="item in currentProduct.includes"
              :key="item.item"
              style="display: flex; align-items: center;"
          >
            <p
                class="details__box__list__item__quantity"
                style="flex-shrink: 0; width: 1.25rem; text-align: right; margin-right: 10px;"
            >
              {{ item.quantity }}<span style="font-size: smaller">x</span>
            </p>
            <p class="details__box__list__item__name" style="flex-grow: 1;">
              {{ item.item }}
            </p>
          </li>
        </ul>
      </div>

    </section>
    <section class="gallery">
      <div class="gallery__left">
        <img
          :src="gallerySrc('first')"
          style="border: #e91e63 1px solid; border-radius: 10px;"
          :alt="`${currentProduct.name} presentation image`"
          class="gallery__left__first"
          @click="slideshow_index = 1"
        />
        <img
          :src="gallerySrc('second')"
          style="border: #e91e63 1px solid; border-radius: 10px;"
          :alt="`${currentProduct.name} presentation image`"
          class="gallery__left__second"
          @click="slideshow_index = 2"
        />
      </div>
      <div class="gallery__right">
        <img
          :src="gallerySrc('third')"
          style="border: #e91e63 1px solid; border-radius: 10px;"
          @click="slideshow_index = 3"
          :alt="`${currentProduct.name} presentation image`"
        />
      </div>
    </section>
    <section class="others" v-if="otherProducts.length > 0">
      <h4 class="others__heading">Tu pourrais aussi aimer</h4>
      <div class="others__container">
        <OthersCard
          v-for="product in otherProducts"
          :key="product.name"
          :product="product"
          @reset-total="resetTotal"
        />
      </div>
    </section>
    <ProductPageNavigation />
  </main>

  <vue-gallery-slideshow :images="images" :index="slideshow_index" @close="slideshow_index = 0"></vue-gallery-slideshow>

</div>
</template>

<script>
import Header from "../components/ProductPage/Header.vue";
import ProductPageNavigation from "../components/ProductPageNavigation.vue";
import data from "../data.json";
import OthersCard from "../components/ProductPage/OthersCard.vue";
import VueGallerySlideshow from 'vue-gallery-slideshow';

const test = "xxxxx";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ProductPage",
  components: { Header, ProductPageNavigation, OthersCard, VueGallerySlideshow},
  emits: ["toggle-menu-show", "add-to-cart"],
  data() {
    return {
      total: 1,
      products: data,
      slideshow_index: null,
      windowSize: null,
      justAdded: false,
      images: []
    };
  },
  metaInfo: {
    link: [
      { rel: 'canonical', href: `https://aucoeurdelamalice.com`+ test}
    ]
  },
  methods: {
    increaseTotal() {
      this.total++;
    },
    decreaseTotal() {
      if (this.total > 1) {
        this.total--;
      }
    },
    addToCartHandler() {
      this.justAdded = true;
      const data = {
        productId: this.currentProduct.id,
        addedQuantity: this.total,
      };
      this.$emit("add-to-cart", data);
    },
    resetTotal() {
      this.total = 1;
      this.justAdded = false;
    },
    setWindowSize() {
      let windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        this.windowSize = "mobile";
      } else if (windowWidth < 1205) {
        this.windowSize = "tablet";
      } else {
        this.windowSize = "desktop";
      }
    },
    gallerySrc(index) {
      if (index === "first") {
        if (this.windowSize === "mobile") {
          return require(`../${this.currentProduct.gallery.first.mobile.slice(
            2
          )}`);
        } else if (this.windowSize === "tablet") {
          return require(`../${this.currentProduct.gallery.first.tablet.slice(
            2
          )}`);
        } else {
          return require(`../${this.currentProduct.gallery.first.desktop.slice(
            2
          )}`);
        }
      } else if (index === "second") {
        if (this.windowSize === "mobile") {
          return require(`../${this.currentProduct.gallery.second.mobile.slice(
            2
          )}`);
        } else if (this.windowSize === "tablet") {
          return require(`../${this.currentProduct.gallery.second.tablet.slice(
            2
          )}`);
        } else {
          return require(`../${this.currentProduct.gallery.second.desktop.slice(
            2
          )}`);
        }
      } else {
        if (this.windowSize === "mobile") {
          return require(`../${this.currentProduct.gallery.third.mobile.slice(
            2
          )}`);
        } else if (this.windowSize === "tablet") {
          return require(`../${this.currentProduct.gallery.third.tablet.slice(
            2
          )}`);
        } else {
          return require(`../${this.currentProduct.gallery.third.desktop.slice(
            2
          )}`);
        }
      }
    },
  },
  created() {
    this.setWindowSize();
    window.addEventListener("resize", this.setWindowSize);
    window.scrollTo(0, 0);
  },
  computed: {
    otherProducts() {
      const filteredProducts = this.products
          .slice()
          .filter((product) =>
              product.category === this.currentProduct.category &&
              product.slug !== this.currentProduct.slug  // Assurez-vous que chaque produit a un identifiant unique
          );

      // Limiter le résultat à 3 produits maximum
      let limitedProducts = filteredProducts.slice(0, 3);

      console.log("Produits de la même catégorie (sans le produit courant, limités à 3) :", limitedProducts);

      return limitedProducts;
    },
    currentProduct() {
      return this.products
        .slice()
        .find((product) => product.slug === this.$route.params.product);
    },
    editSrc() {
      if (this.windowSize === "mobile") {
        return require(`../${this.currentProduct.image.mobile.slice(2)}`);
      } else if (this.windowSize === "tablet") {
        return require(`../${this.currentProduct.image.tablet.slice(2)}`);
      } else {
        return require(`../${this.currentProduct.image.desktop.slice(2)}`);
      }
    },
    editedText() {
      const paragraphs = [];
      let myString = this.currentProduct.features;
      while (myString.includes("\n\n")) {
        let index = myString.indexOf("\n\n");
        paragraphs.push(myString.slice(0, index));
        myString = myString.slice(index + 2);
      }
      if (paragraphs.length > 0) {
        paragraphs.push(myString);
      } else {
        paragraphs.push(this.currentProduct.features);
      }
      return paragraphs;
    },
  },
  mounted(){
    this.images= [this.editSrc, this.gallerySrc("first"), this.gallerySrc("second"), this.gallerySrc("third")];
  }
};
</script>

<style lang="scss" scoped>
main {
  width: 35.7rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 68.9rem;
  }

  @media (min-width: 1205px) {
    width: 111rem;
  }
}

.back-link {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: #7d7d7d;
  margin: 1.6rem 0 2.4rem 0;
  display: block;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #e91e63;
  }

  @media (min-width: 768px) {
    margin-top: 3.3rem;
  }

  @media (min-width: 1205px) {
    margin-top: 7.9rem;
  }
}

.overview {
  margin: 2.4rem auto 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1205px) {
    margin-top: 5.6rem;
  }

  & * {
    text-align: left;
  }

  &__image {
    width: 100%;
    border-radius: 0.8rem;
    object-fit: cover;
    object-position: center;

    @media (min-width: 768px) {
      height: 48rem;
      width: 28.1rem;
    }

    @media (min-width: 1205px) {
      width: 54rem;
      height: 56rem;
    }
  }

  &__text {
    margin-top: 3.2rem;

    @media (min-width: 768px) {
      width: 33.95rem;
      margin-top: 0;
      margin-left: 6.9rem;
    }

    @media (min-width: 1205px) {
      margin: 0;
      margin-left: 12.5rem;
      width: 44.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      * {
        text-align: left;
      }
    }

    &__tag {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.912rem;
      letter-spacing: 1rem;
      text-transform: uppercase;
      color: #e91e63;
    }

    &__title {
      margin: 2.4rem 0;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.825rem;
      letter-spacing: 0.1rem;
      text-transform: uppercase;

      @media (min-width: 768px) {
        font-size: 4rem;
        line-height: 4.4rem;
        letter-spacing: 0.143rem;
      }
    }

    &__description {
      margin-bottom: 2.4rem;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: #8d8d8d;
    }

    &__price {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.459rem;
      letter-spacing: 0.129rem;
      margin-bottom: 3.1rem;
    }

    &__btn-section {
      display: flex;
      align-items: center;

      &__number {
        display: flex;
        align-items: center;
        background: #f1f1f1;
        padding: 1.5rem;
        width: 12rem;
        justify-content: space-between;
        margin-right: 1.6rem;

        & * {
          background: none;
          border: none;
          font-weight: 700;
          font-size: 1.3rem;
          line-height: 1.776rem;
          letter-spacing: 0.1rem;
        }

        & button {
          color: #b5b5b5;
          transition: all 0.3s ease;

          &:hover {
            color: #e91e63;
          }
        }
      }
    }
  }
}

.details {
  margin: 1rem auto 5rem auto;
  width: 100%;

  @media (min-width: 1205px) {
    margin: 5rem auto 11.3rem auto;
    display: flex;
    align-items: flex-start;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.6rem;
    letter-spacing: 0.086rem;

    @media (min-width: 768px) {
      font-size: 3.2rem;
      letter-spacing: 0.114rem;
    }
  }

  &__features {
    @media (min-width: 1205px) {
      width: 63.5rem;
    }
    &__text {
      margin-top: 2.4rem;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: #7d7d7d;

      @media (min-width: 1205px) {
        margin-top: 3.2rem;
      }
    }
  }

  &__box {
    margin-top: 3rem;

    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
    }

    @media (min-width: 1205px) {
      width: 35rem;
      margin: 0;
      margin-left: 12.5rem;
      display: block;
    }

    &__heading {
      width: 35rem;
    }

    &__list {
      margin-top: 2.4rem;

      @media (min-width: 768px) {
        margin-top: 0;
      }

      @media (min-width: 1205px) {
        margin-top: 3.2rem;
      }

      * {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }

      &__item {
        display: flex;
        align-items: center;

        &__quantity {
          font-weight: 700;
          color: #e91e63;
        }

        &__name {
          font-weight: 500;
          margin-left: 2.1rem;
          color: #7d7d7d;
          text-transform: capitalize;
        }
      }
    }
  }
}

.gallery {
  @media (min-width: 768px) {
    display: flex;
  }

  img {
    width: 100%;
    border-radius: 0.8rem;
    margin: 1rem 0;
    object-fit: cover;
    object-position: center;

    @media (min-width: 768px) {
      margin: 0;
    }
  }

  &__left {
    @media (min-width: 768px) {
      width: 27.7rem;
    }

    @media (min-width: 1205px) {
      width: 44.5rem;
    }

    img {
      height: 17.4rem;

      @media (min-width: 1205px) {
        height: 28rem;
      }
    }

    &__first {
      margin-top: 0 !important;

      @media (min-width: 768px) {
        margin-bottom: 2rem !important;
      }

      @media (min-width: 1205px) {
        margin-bottom: 3.2rem !important;
      }
    }
  }

  &__right {
    img {
      margin-bottom: 0 !important;
      height: 36.8rem;

      @media (min-width: 768px) {
        width: 39.5rem;
        margin-left: 1.8rem;
      }

      @media (min-width: 1205px) {
        width: 64.5rem;
        height: 59.2rem;
        margin-left: 3rem;
      }
    }
  }
}

.others {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: calc(12rem - 5.2rem);

  &__heading {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.6rem;
    letter-spacing: 0.086rem;
    margin-bottom: 4rem;

    @media (min-width: 768px) {
      margin-top: 8rem;
      font-size: 3.2rem;
      line-height: 3.6rem;
      letter-spacing: 0.114rem;
      margin-bottom: 5.6rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
}

.just-added {
  background: #e91e63;
}
</style>
